/* Importing lib's SCSS files. */
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-icons/font/bootstrap-icons";
@import "~ngx-toastr/toastr.css";
@import url("https://fonts.googleapis.com/css2?family=Mulish&display=swap");
@import "../src/colors.scss";

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-size: 14px;
  font-family: "Mulish", sans-serif;
  font-weight: normal;
  font-style: normal;
  overflow-x: hidden;
  position: relative;
  background-color: var(--bs-body-background);
}

.cs-tool-dark-mode {
  filter: invert(100%) brightness(55%);
}

@media (prefers-color-scheme: dark) {
  .cs-tool-dark-mode {
    filter: invert(100%) brightness(55%);
  }
}

/*--------------------------------------------------------------
# Shared
--------------------------------------------------------------*/

.pagetitle {
  height: 73px;
  margin: 0px;
  h1 {
    color: $black;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.3;
  }
}

.accordion-body {
  padding: 0px !important;
}

.new-page-title {
  color: $black !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 1.3 !important;
}

/*--------------------------------------------------------------
# Custom
--------------------------------------------------------------*/

.table-overflow {
  overflow-y: hidden !important;
  overflow-x: auto !important;
}

.mh-100 {
  min-height: 100vh;
}

.mh-20 {
  min-height: 20vh;
}

.min-vh-75 {
  min-height: 55vh !important;
}

.login-bg {
  background-image: url("../src/assets/images/icons/login-bg.svg");
}

.ml-72 {
  margin-left: 72px;
}

.rl {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rr {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.border-red {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(245, 10, 10, 0.25);
}

.border-green {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.search {
  width: 660px;
  button:focus,
  input:focus {
    outline: none !important;
    box-shadow: none;
  }
}

.table-border {
  border-collapse: separate;
  border-spacing: 0px 4px;

  thead tr th {
    border: none;
    color: $steel !important;
    font-weight: bold;
    line-height: 1.5;
    font-size: 14px;
    margin: 0px;
  }

  tbody {
    // .premium {
    //   border-color: $gold;
    // }
    .rejected-template {
      border-color: $danger !important;
    }
    tr {
      > td {
        border-top-width: 1px;
      }
      :first-child {
        border-left-width: 1px;
      }
      :last-child {
        border-right-width: 1px;
      }
    }
  }
}

.fb {
  font-weight: 700;
  line-height: 18px;
}

.fb-700 {
  font-weight: 700;
}

.custom-toaster {
  margin: 0px 5px 6px !important;
  right: 0 !important;
}

.green-box,
.red-box {
  display: inline-block;
  padding: 2px 6px;
  border-radius: 3px;
  font-size: 14px;
  line-height: 1.5;
}

.green-box {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.red-box {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

/*--------------------------------------------------------------
# New Design
--------------------------------------------------------------*/
// Main
a {
  text-decoration: none;
  color: var(--bs-black);
  &:link,
  &:visited,
  &:hover,
  &:active {
    text-decoration: none;
    color: var(--bs-black);
  }
}

.dialog-label {
  background: #df3a5c !important;
  color: #fff !important;
  font-style: bold !important;
  border: 1px solid #df3a5c !important;
}

.custom-spinner {
  background: var(--bs-black);
  border-radius: 8px;

  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--bs-white);
  }
}

// Labels
.get-chat-label {
  color: var(--bs-alert-color) !important;
  background: var(--bs-alert-bg) !important;
  border: 1px solid var(--bs-alert-border-color) !important;
  font-style: bold !important;
}

.status-label {
  color: var(--bs-alert-color) !important;
  background: rgba(39, 200, 245, 0.8) !important;
  border: rgba(39, 200, 245, 0.8) !important;
  font-style: bold !important;
}

.integration-label {
  color: var(--bs-white) !important;
  background: var(--bs-danger) !important;
  border: 1px solid var(--bs-danger) !important;
  font-style: bold !important;
}

.prepaid-partner {
  color: var(--bs-white) !important;
  background: #738276 !important;
  border: 1px solid #738276 !important;
  font-style: bold !important;
}

.cloud-api-label {
  color: var(--bs-alert-color-blue) !important;
  background: var(--bs-alert-bg-blue) !important;
  border: 1px solid var(--bs-alert-border-color-blue) !important;
  font-style: bold !important;
}

.oba-label {
  color: var(--bs-success) !important;
  background: rgba(32, 175, 90, 0.12) !important;
  border: none !important;
  font-style: bold;
  gap: 0px !important;
}

.premium-label {
  color: var(--bs-alert-color) !important;
  background: rgb(255, 255, 0) !important;
  border: 1px solid var(--bs-alert-border-color) !important;
  gap: 0px !important;
}

// Buttons
.btn-custom {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 8px;
  width: 32px;
  height: 32px;
  background: var(--bs-white);
  border: 1px solid var(--bs-azureish-white);
  border-radius: 8px;
  flex: none;
  order: 1;
  flex-grow: 0;

  i {
    color: var(--bs-black);
  }
}

.btn-primary {
  padding: 8px 20px !important;
  gap: 10px !important;
  height: 40px !important;
  background: var(--bs-primary) !important;
  color: var(--bs-white) !important;
  border-radius: 6px !important;
  border: none !important;
}

.danger-button {
  background: red !important;
}

.info-button {
  background: rgb(13, 202, 240) !important;
}

.text-orange {
  color: #ffa500 !important;
}

.btn-copy {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px;
  gap: 10px;
  border: none;
  position: fixed;
  width: 44px;
  height: 44px;
  right: 24px;
  bottom: 24px;
  background: var(--bs-whale-dark);
  border-radius: 8px;
  flex: none;
  order: 2;
  flex-grow: 0;
  z-index: 999;
}

.btn-icon-checkbox {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 8px;
  width: 20px;
  height: 20px;
  background: var(--bs-white);
  border: 1px solid var(--bs-azureish-white);
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;

  i {
    color: var(--bs-snow);
  }
}

.dropdown-wrap {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.no-wrap-tooltip {
  .tooltip {
    white-space: nowrap !important;
  }
}

.cache-btn {
  img {
    background: var(--bs-snow);
    border: 1px solid var(--bs-azureish-white);
    border-radius: 8px;
    color: var(--bs-primary);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 8px;
    width: 32px;
    height: 32px;
    flex: none;
    order: 1;
    flex-grow: 0;
  }
}

.red-btn-checkbox {
  background: var(--bs-danger) !important;
  border: 1px solid var(--bs-danger) !important;
}

.blue-btn-checkbox {
  background: var(--bs-primary) !important;
  border: 1px solid var(--bs-primary) !important;
}

button:disabled {
  cursor: not-allowed !important;
  border: 1px solid red !important;
}

.confirmation-modal {
  &:after {
    display: none !important;
  }
}

.arrow-right {
  &:after {
    position: absolute !important;
    right: 0 !important;
    margin: 10px;
  }
}

.confirmation-button {
  height: 34.13px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: var(--bs-danger) !important;
  border: 1px solid var(--bs-smoke-darker);
  &:hover,
  &:focus {
    background-color: transparent !important;
    color: var(--bs-danger) !important;
  }
}

// Table
.th-label {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--bs-black);
  flex: none;
  order: 1;
  flex-grow: 0;
}

.bg-gray thead tr th {
  background-color: var(--bs-body-background) !important;
}

td {
  vertical-align: middle;
}

// Modal
.modal-header {
  background: var(--bs-snow) !important;
  .modal-title {
    font-weight: 700 !important;
    font-size: 20px !important;
    line-height: 25px !important;
    color: var(--bs-black) !important;
  }
}

.btn-modal-close {
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  gap: 10px;
  width: 81px;
  height: 40px;
  border: 1px solid var(--bs-snow-darker);
  border-radius: 6px;
  flex: none;
  order: 0;
  flex-grow: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--bs-black);
}

.btn-modal-proceed {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  gap: 10px;
  width: 101px;
  height: 40px;
  background: var(--bs-btn-bg);
  border-radius: 6px;
  flex: none;
  order: 1;
  flex-grow: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #fcfcfc;
  border: none;
}

// Dropdown

.dropdown-menu {
  padding: 0px !important;

  a {
    border-radius: 0px !important;
  }
}

.heading-main {
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  color: #212121;
}

.heading-prepend {
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #212121;
}

.section-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: #212121;
  padding-right: 15px;
  margin: 0;
}

.propery-name {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #212121;
}

.badge-circle {
  border-radius: 30px;
  padding: 5px 15px;
  height: 21px;
  font-size: 12px;
}
.badge-success {
  background-color: rgba(32, 175, 90, 0.12);
  color: var(--bs-success);
}
.badge-danger {
  background-color: rgba(245, 0, 50, 0.12);
  color: var(--bs-danger);
}

.dropdown-menu {
  --bs-dropdown-bg: #fff !important;
}

.design-table {
  thead {
    display: table;
    width: 100%;
    th {
      width: 17%;
    }
    th:first-child {
      padding-left: 1.5rem;
    }
  }
  tbody {
    display: table;
    width: 100%;
    background-color: #ffffff !important;
    border: none;
    border: 8px solid #ffffff !important;
    border-radius: 8px;
    border-spacing: 0px;
    td {
      width: 17%;
      font-size: 14px;
      vertical-align: middle;
    }

    tr {
      background-color: #ffffff !important;
      td {
        background-color: #ffffff !important;
      }
    }

    td:first-child {
      padding-left: 1.5rem;
    }
    .premium {
      background: linear-gradient(
        270deg,
        rgba(17, 147, 240, 0.04) 0%,
        rgba(17, 147, 240, 0) 100%
      );
    }

    // tr:hover {
    //   background-color: rgba(17, 147, 240, 0.04) !important;
    // }
  }

  tr {
    border-spacing: 0px;
    border-bottom: 1px solid #e5e9f2 !important;
    height: 53px;
  }
}

.segment-container {
  border: none;
}

.custom-tabs {
  .nav-tabs {
    border-bottom: none;
  }

  .nav-link {
    border: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    padding: 16px 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #575757;
    border-radius: 8px 8px 0px 0px;
  }
}

.phone-details {
  .table > :not(caption) > * > * {
    padding: 0px;
    padding-left: 16px;
    line-height: 48px;
    border: 0;
    background-color: #ffffff !important;
  }
  tr {
    border-bottom: 1px solid #e5e9f2 !important;
    background-color: #ffffff !important;
  }
}

.modal-body,
.modal-footer {
  background-color: #fff !important;
  .form-control {
    background-color: #fff !important;
  }
}

.background-white {
  background-color: #fff !important;
}

.col-waba {
  flex: 2.8;
}
.col-client {
  flex: 1.2;
  margin-left: 40px;
}

.inner-tabs {
  ul {
    border-bottom: 1px solid #e5e9f2 !important;
  }
  .nav-link {
    background-color: #ffffff !important;
  }
  .nav-link.active {
    color: #1193f0;
    border-bottom: 1px solid #1193f0 !important;
  }
}

.tab-header {
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: #212121 !important;
}

.tabs-content-info {
  min-height: 500px;
}

.status {
  span {
    width: 100px !important;
    font-size: 13px;
    padding: 0.25rem !important;
    text-transform: uppercase !important;
    border-radius: var(--bs-border-radius) !important;
  }
  .text-primary {
    background-color: rgb(202, 233, 248);
    color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
  }
  .text-warning {
    background-color: rgb(248, 237, 212);
    color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
  }
  .text-success {
    background-color: rgb(212, 248, 218);
    color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
  }
  .text-danger {
    background-color: rgb(248, 212, 212);
    color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
  }
}

.input-button {
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
}
.input-select {
  border-bottom: 1px solid #ced4da;
  padding: 0.375rem 0.75rem;
}

.ha {
  height: auto !important;
}

// TOS
.tos-tab-title {
  border-bottom: 2px solid #dee2e6;
  margin-bottom: 20px;
  padding-bottom: 10px;
  h2 {
    font-size: 1.45rem;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    letter-spacing: 1px;
  }
}
