$background: rgba(252, 253, 254, 1);
$white: rgba(252, 253, 254, 1);
$blue-light: rgba(89, 180, 245, 1);
$blue: rgba(17, 147, 240, 1);
$blue-dark: rgba(11, 103, 170, 1);
$black: rgba(33, 33, 33, 1);
$steel: rgba(87, 87, 87, 1);
$concrete: rgba(153, 153, 153, 1);
$whale-light: rgba(137, 156, 166, 1);
$whale: rgba(61, 93, 116, 1);
$whale-dark: rgba(48, 74, 98, 1);
$smoke-darker: rgba(192, 204, 218, 1);
$smoke-dark: rgba(211, 220, 230, 1);
$smoke: rgba(224, 230, 237, 1);
$snow-darker: rgba(229, 233, 242, 1);
$snow-dark: rgba(239, 242, 247, 1);
$snow: rgb(243, 244, 247);
$snow-v2: rgba(249, 250, 252, 1);
$success: rgba(32, 175, 90, 1);
$danger: rgba(245, 0, 50, 1);
$danger-dark: rgba(219, 0, 45, 1);
$warning: rgba(255, 199, 89, 1);
$warning-dark: rgba(251, 181, 43, 1);
$green-light: rgba(128, 232, 170, 1);
$green: rgba(63, 220, 127, 1);
$green-dark: rgba(32, 175, 90, 1);
$turquoise-light: rgba(133, 227, 240, 1);
$turquoise: rgba(65, 211, 231, 1);
$turquoise-dark: rgba(25, 174, 195, 1);
$pink-light: rgba(255, 143, 166, 1);
$gold: rgb(255, 215, 0);
$blue: #0d6efd !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #f50032 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #20af5a !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;

:root {
  --bs-body-background: #eff2f7;
  --bs-success-rgb: 32, 175, 90;
  --bs-danger-rgb: 245, 0, 50;
  --bs-primary-rgb: 17, 147, 240;
  --bs-danger: #F50032;
  --bs-success: #20AF5A;
  --bs-primary: #1193F0;
  --bs-white: #FCFDFE;
  --bs-smoke-darker: #C0CCDA;
  --bs-smoke: #E0E6ED;
  --bs-smoke-dark: #D3DCE6;
  --bs-snow-darker: #E5E9F2;
  --bs-primary-rgba: 17, 147, 240, 0.1;
  --bs-black: #212121;
  --bs-pagination-bg: transparent;
  --bs-btn-bg: #1193F0;
  --bs-btn-border-color: #1193F0;
  --bs-snow: #f9fafc;
  --bs-azureish-white: #e0e6ed;
  --bs-alert-color: #664d03;
  --bs-alert-bg: #fff3cd;
  --bs-alert-border-color: #ffecb5;
  --bs-alert-color-blue: #055160;
  --bs-alert-bg-blue: #cff4fc;
  --bs-alert-border-color-blue: #b6effb;
  --bs-whale-dark: #304a62;
}
